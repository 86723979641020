/**
 * SysTagCategoryVO
 */
export interface SysTagCategoryVO {
  /**
   * 标签分类名称
   */
  categoryName?: string;
  /**
   * 业务分类 0雇主 1经纪人 2切片 3职位 4管家
   */
  categoryType?: number;
  /**
   * ID
   */
  id?: number;
  latestUpdateBy?: number;
  /**
   * 更新人
   */
  latestUpdateName?: string;
  /**
   * 更新时间
   */
  latestUpdateTime?: Date;
  /**
   * 显示状态 true显示 false隐藏
   */
  showState?: boolean;
  /**
   * 标签数
   */
  tagCount?: number;
}

/**
 * SysTagLibVO
 */
export interface SysTagLibVO {
  /**
   * 分类ID
   */
  categoryId?: number;
  /**
   * 标签分类名称
   */
  categoryName?: string;
  /**
   * ID
   */
  id?: number;
  latestUpdateBy?: number;
  /**
   * 更新人
   */
  latestUpdateName?: string;
  /**
   * 更新时间
   */
  latestUpdateTime?: Date;
  /**
   * 标签名称
   */
  libName?: string;
  /**
   * 显示状态 true显示 false隐藏
   */
  showState?: boolean;
  /**
   * 标签数
   */
  tagCount?: number;
}

//业务分类
export const CategoryType = {
  0: '雇主',
  1: '经纪人',
  2: '切片',
  3: '职位',
  4: '管家',
  5: '主播',
} as const;
