import { CategoryType } from '@/pages/tagsManagement/type.ts';
import { Form, Input, Modal, Radio, Select } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type TagsModel from './model';

const TagsTab = ({ store }: { readonly store: TagsModel }) => {
  const type = new URLSearchParams(window.location.search)?.get('type');
  const { visible, onClose, setRef, onSubmit, isEdit, categoriesList } = store;
  return (
    <Modal
      title={isEdit ? `编辑${CategoryType[type]}标签` : `添加${CategoryType[type]}标签`}
      open={visible}
      onCancel={onClose}
      maskClosable={false}
      onOk={onSubmit}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        ref={setRef}
        initialValues={{ showState: true }}
      >
        <Form.Item
          label={'标签名称'}
          name="libName"
          rules={[{ required: true, message: '请填写标签名称' }]}
        >
          <Input
            placeholder="请输入"
            maxLength={8}
          />
        </Form.Item>
        <Form.Item
          label={'标签分类'}
          name="categoryId"
          rules={[{ required: true, message: '请选择标签分类' }]}
        >
          <Select
            placeholder="请选择"
            options={categoriesList}
          />
        </Form.Item>
        <Form.Item
          label="显示状态"
          name="showState"
        >
          <Radio.Group>
            <Radio value={true}>显示</Radio>
            <Radio value={false}>隐藏</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default observer(TagsTab);
