import { CategoryType } from '@/pages/tagsManagement/type.ts';
import { Form, Input, Modal, Radio } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type TagsCategoryModel from './model';

const TagsCategoryTab = ({ store }: { readonly store: TagsCategoryModel }) => {
  const type = new URLSearchParams(window.location.search)?.get('type');
  const { visible, onClose, setRef, onSubmit, isEdit } = store;
  return (
    <Modal
      title={isEdit ? `编辑${CategoryType[type]}标签分类` : `添加${CategoryType[type]}标签分类`}
      open={visible}
      onCancel={onClose}
      maskClosable={false}
      onOk={onSubmit}
    >
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        ref={setRef}
        initialValues={{ showState: true }}
      >
        <Form.Item
          label={'分类名称'}
          name="categoryName"
          rules={[{ required: true, message: '请填写标签分类名称' }]}
        >
          <Input
            placeholder="请输入"
            maxLength={8}
          />
        </Form.Item>
        <Form.Item
          label="显示状态"
          name="showState"
        >
          <Radio.Group>
            <Radio value={true}>显示</Radio>
            <Radio value={false}>隐藏</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default observer(TagsCategoryTab);
