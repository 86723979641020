import type { SysTagCategoryVO } from '@/pages/tagsManagement/type.ts';
import { type PaginationData, request } from '@/utils';
import { api } from '@/utils/api.ts';
import type { FormInstance } from 'antd';
import { action, observable } from 'mobx';

export default class TagsModel {
  @observable public visible = false;

  @observable public categoryModel: SysTagCategoryVO = null;
  @observable public ref: FormInstance = null;
  @observable public parent = null;
  @observable public isEdit = false;
  @observable public categoriesList: { label: string; value: number }[] = [];
  private type = 0;
  constructor(parent) {
    this.parent = parent;
    this.init();
  }
  init = () => {
    const paramType: string = new URLSearchParams(window.location.search)?.get('type');
    if (paramType) {
      this.type = Number(paramType);
    }
  };
  @action
  queryCategoriesList = async () => {
    const results = await request<PaginationData<SysTagCategoryVO>>({
      url: api.category.getList,
      method: 'post',
      data: { pageNum: 1, pageSize: 1000, showState: true, categoryType: this.type },
    });
    this.categoriesList = results.data.list.map((e) => {
      return { label: e.categoryName, value: e.id };
    });
  };
  @action
  setRef = (ref: FormInstance) => {
    this.ref = ref;
  };
  @action
  onShow = async (data?: SysTagCategoryVO): Promise<void> => {
    await this.queryCategoriesList();
    if (data) {
      this.isEdit = true;
      this.categoryModel = data;
      setTimeout(() => {
        this.ref.setFieldsValue(data);
      }, 0);
    } else {
      this.isEdit = false;
    }
    this.visible = true;
  };
  @action
  onClose = () => {
    this.ref.resetFields();
    this.isEdit = false;
    this.visible = false;
  };
  @action
  onSubmit = async () => {
    const values = await this.ref.getFieldsValue();
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }
    if (this.isEdit) {
      await request({
        url: api.tag.edit,
        method: 'post',
        data: { ...this.categoryModel, ...values, categoryType: this.type },
      });
    } else {
      await request({ url: api.tag.add, method: 'post', data: { ...values, categoryType: this.type } });
    }
    console.log('values', values);
    this.parent.tagsMainStore.gridModel.onQuery();
    this.onClose();
  };
}
