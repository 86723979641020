import { CategoryType } from '@/pages/tagsManagement/type.ts';
import { Tabs } from 'antd';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { MainSubStructure } from '../../utils';
import TagsCategoryTab from './components/categories';
import TagsTab from './components/tags';
import Model from './model.tsx';

const TagsManagement = () => {
  const store = useMemo(() => new Model(), []);
  const type = new URLSearchParams(window.location.search)?.get('type');
  const { tagsMainStore, tagsCategoryMainStore, tagsCategoryStore, tagsStore, onChangeTab } = store;
  const items = useMemo(() => {
    return [
      {
        key: 'tag',
        label: `${CategoryType[type]}标签库`,
        children: (
          <div style={{ height: 'calc(100vh - 80px)' }}>
            <MainSubStructure store={tagsMainStore} />
          </div>
        ),
      },
      {
        key: 'category',
        label: `${CategoryType[type]}标签分类`,
        children: (
          <div style={{ height: 'calc(100vh - 80px)' }}>
            <MainSubStructure store={tagsCategoryMainStore} />
          </div>
        ),
      },
    ];
  }, [type, tagsMainStore, tagsCategoryMainStore]);
  return (
    <div className={'h-screen flex flex-col'}>
      <Tabs
        defaultActiveKey="1"
        tabPosition={'top'}
        items={items}
        className={'flex-1 flex flex-col'}
        onChange={onChangeTab}
      />
      <TagsCategoryTab store={tagsCategoryStore} />
      <TagsTab store={tagsStore} />
    </div>
  );
};

export default observer(TagsManagement);
