import TagsCategoryModel from '@/pages/tagsManagement/components/categories/model.ts';
import TagsModel from '@/pages/tagsManagement/components/tags/model.ts';
import { CategoryType } from '@/pages/tagsManagement/type.ts';
import { type BaseData, MainSubStructureModel, RenderByPermission, request } from '@/utils';
import { api } from '@/utils/api.ts';
import { Button, Modal, message } from 'antd';
import { action, observable } from 'mobx';
import React from 'react';

export default class TagsManagementModel {
  @observable public tagsCategoryStore: TagsCategoryModel = new TagsCategoryModel(this);
  @observable public tagsStore: TagsModel = new TagsModel(this);
  private type = 0;
  constructor() {
    this.init();
  }
  init = () => {
    const paramType: string = new URLSearchParams(window.location.search)?.get('type');
    if (paramType) {
      this.type = Number(paramType);
      this.tagsMainStore.buttons = [
        {
          text: '新增标签',
          permissionId: '2',
          handleClick: () => {
            this.tagsStore.onShow();
          },
        },
      ];
      this.tagsCategoryMainStore.buttons = [
        {
          text: '新增标签分类',
          permissionId: '62',
          handleClick: () => {
            this.tagsCategoryStore.onShow();
          },
        },
      ];
      this.tagsMainStore.gridModel.onQuery();
    }
  };

  // 删除标签
  private onDeleteTagHandler = (ids: string) => {
    Modal.confirm({
      title: '提示',
      content: `是否确认删除该${CategoryType[this.type]}标签?`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await request({ url: api.tag.delete, method: 'post', data: { ids } });
        message.success('删除成功');
        this.tagsMainStore.gridModel.onQuery();
      },
    });
  };

  public tagsMainStore: MainSubStructureModel = new MainSubStructureModel({
    buttons: [],
    grid: {
      columns: [
        {
          key: 'opreations',
          name: '操作',
          width: 200,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <Button
                    onClick={() => {
                      this.tagsStore.onShow(row);
                    }}
                    type="link"
                  >
                    编辑
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_4`}>
                  <Button
                    onClick={() => {
                      this.onDeleteTagHandler(row.id);
                    }}
                    type="link"
                  >
                    删除
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'libName',
          name: '标签名称',
        },
        {
          key: 'categoryName',
          name: '所属分类',
        },
        {
          key: 'showState',
          name: '显示状态',
          formatter: ({ row }) => {
            return row.showState ? '显示' : '隐藏';
          },
        },
        {
          key: 'latestUpdateName',
          name: '更新人',
        },
        {
          key: 'latestUpdateTime',
          name: '更新时间',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: true,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params: Record<string, any>) => {
        return request<BaseData<any>>({
          url: api.tag.getList,
          method: 'POST',
          data: { ...params, categoryType: this.type },
        });
      },
    },
  });

  // 删除标签分类
  private onDeleteCategoryHandler = (ids: string) => {
    Modal.confirm({
      title: '提示',
      content: `是否确认删除该${CategoryType[this.type]}标签分类?`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await request({ url: api.category.delete, method: 'post', data: { ids } });
        message.success('删除成功');
        this.tagsCategoryMainStore.gridModel.onQuery();
      },
    });
  };
  //标签分类列表
  public tagsCategoryMainStore: MainSubStructureModel = new MainSubStructureModel({
    buttons: [],
    grid: {
      columns: [
        {
          key: 'opreations',
          name: '操作',
          width: 200,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_60`}>
                  <Button
                    onClick={() => {
                      this.tagsCategoryStore.onShow(row);
                    }}
                    type="link"
                  >
                    编辑
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_61`}>
                  <Button
                    onClick={() => {
                      if (row.tagCount > 0) {
                        message.error('不可删除，分类下存在标签，请先删除！');
                        return;
                      }
                      this.onDeleteCategoryHandler(row.id);
                    }}
                    type="link"
                  >
                    删除
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'categoryName',
          name: '标签分类名称',
        },
        {
          key: 'tagCount',
          name: '标签数',
        },
        {
          key: 'showState',
          name: '显示状态',
          formatter: ({ row }) => {
            return row.showState ? '显示' : '隐藏';
          },
        },
        {
          key: 'latestUpdateName',
          name: '更新人',
        },
        {
          key: 'latestUpdateTime',
          name: '更新时间',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: true,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params: Record<string, any>) => {
        return request<BaseData<any>>({
          url: api.category.getList,
          method: 'POST',
          data: { ...params, categoryType: this.type },
        });
      },
    },
  });
  @action
  onChangeTab = (tabKey: string) => {
    if (tabKey === 'tag') {
      this.tagsMainStore.gridModel.onQuery();
    } else {
      this.tagsCategoryMainStore.gridModel.onQuery();
    }
  };
}
